import { useEffect, useState } from 'react';
import { BasicShowPreviewProps, parseLinearSpeed } from './helpers';
import LightFixture from '../LightFixture';

export default function CurtainShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  const [leftCurtain, setLeftCurtain] = useState(0); // Left curtain position
  const [rightCurtain, setRightCurtain] = useState(0); // Right curtain position
  const [direction, setDirection] = useState(1); // Direction of movement (1 = inward, -1 = outward)
  const [colorInner, setColorInner] = useState(1); // Inside curtain color index
  const [colorOuter, setColorOuter] = useState(0); // Outside curtain color index
  const [stepSize, setStepSize] = useState(1); // Number of steps per movement
  const [delayFrame, setDelayFrame] = useState(1); // Frame delay for movement
  const [end, setEnd] = useState(pixelLayout[0].length - 1); // Right boundary of the curtain

  // Parse the speed once at the start or when the speed changes
  useEffect(() => {
    parseLinearSpeed(speed, pixelLayout[0].length, setStepSize, setDelayFrame);
    setEnd(pixelLayout[0].length - 1); // Set the right boundary
    setRightCurtain(pixelLayout[0].length - 1);
  }, [speed, pixelLayout]);

  // Update the curtain position at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      let firstLeftCurtain = leftCurtain + direction * stepSize;
      let firstRightCurtain = rightCurtain - direction * stepSize;

      setLeftCurtain(firstLeftCurtain);
      setRightCurtain(firstRightCurtain);

      if (firstLeftCurtain <= 0 || firstRightCurtain >= end) {
        firstLeftCurtain = 0;
        firstRightCurtain = end;
        setLeftCurtain(0);
        setRightCurtain(end);
        setDirection(direction * -1);
        let newColorOuter = colorInner + 1;
        if (newColorOuter > colors.length - 1) {
          setColorOuter(0);
        } else setColorOuter(newColorOuter);
      }
      // Handle boundary cases and color switching
      if (firstLeftCurtain > firstRightCurtain + stepSize) {
        setDirection(direction * -1);
        let newColorInner = colorOuter + 1;
        if (newColorInner > colors.length - 1) {
          setColorInner(0);
        } else setColorInner(newColorInner);
      }
    }, delayFrame * 42);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [
    delayFrame,
    direction,
    stepSize,
    end,
    colors.length,
    leftCurtain,
    rightCurtain,
    colorInner,
    colorOuter,
  ]);

  if (!colors || !colors.length || !pixelLayout) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          // Determine color based on curtain positions
          const isInner =
            columnIndex >= leftCurtain && columnIndex <= rightCurtain;
          const color = isInner ? colors[colorInner] : colors[colorOuter];
          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
