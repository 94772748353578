import { useEffect, useState } from 'react';
import { BasicShowPreviewProps, parseLinearSpeed } from './helpers';
import LightFixture from '../LightFixture';

export default function ChaseShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  const [splitLocation, setSplitLocation] = useState(0); // Split position between left and right colors.
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward.
  const [forward, setForward] = useState(true);
  const [colorIndex, setColorIndex] = useState(0); // Index of the current active color.
  const [colorLeft, setColorLeft] = useState(0);
  const [colorRight, setColorRight] = useState(1);
  const [stepSize, setStepSize] = useState(1);
  const [delayFrame, setDelayFrame] = useState(1);

  function setDir(forward: boolean, newC: number, prev: number) {
    parseLinearSpeed(speed, pixelLayout[0].length, setStepSize, setDelayFrame);
    if (!forward) {
      setDirection(-1);
      setSplitLocation(pixelLayout[0].length - 1);
      setColorLeft(prev);
      setColorRight(newC);
    } else {
      setDirection(1);
      setSplitLocation(0);
      setColorLeft(newC);
      setColorRight(prev);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const newSplit = splitLocation + direction;
      setSplitLocation(splitLocation + direction * stepSize);

      if (
        newSplit >= pixelLayout[0].length + stepSize - 1 ||
        newSplit < 0 - stepSize
      ) {
        const prevIndex = colorIndex;
        let newColorIndex = colorIndex + 1;

        if (newColorIndex >= colors.length) {
          setColorIndex(0);
          newColorIndex = 0;
        } else setColorIndex(newColorIndex);
        setDir(!forward, newColorIndex, prevIndex);
        setForward(!forward);
      }
    }, delayFrame * 42);

    return () => clearInterval(interval); // Cleanup interval on unmount.
  }, [direction, colors.length, pixelLayout, splitLocation, colorIndex]);

  if (!colors || !colors.length || colors.length <= 1 || !pixelLayout) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity.
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          // Determine color based on split location.
          const isLeft = columnIndex <= splitLocation;
          const color = isLeft ? colorLeft : colorRight;
          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={colors[color]}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
