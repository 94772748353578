import LightFixture from '../LightFixture';
import { BasicShowPreviewProps } from './helpers';

export default function SolidShowPreview({
  colors,
  pixelLayout,
}: BasicShowPreviewProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity.
      }}
    >
      {pixelLayout.flat().map((colorIndex, idx) => {
        const color = colors[0] || [0, 0, 0]; // Fallback to black if the color is missing.
        return (
          <LightFixture
            key={idx + idx}
            row={idx}
            column={idx}
            color={color}
            pixel={colorIndex}
          />
        );
      })}
    </div>
  );
}
