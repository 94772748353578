export interface BasicShowPreviewProps {
  colors: number[][]; // Colors array: each row corresponds to a group of RGB values.
  pixelLayout: number[][]; // Grid layout: each number references an index in the `colors` array.
  speed: number;
  backward?: boolean;
}
export function parseLinearSpeed(
  speed: number,
  len: number,
  setStepSize: Function,
  setDelayFrame: Function,
) {
  const frameTimeMS = 42;
  const linear = [8400, 7200, 6100, 5000, 4000, 3100, 2300, 1600, 1100, 800];
  let effectTime = linear[speed - 1];
  let frameCount = effectTime / frameTimeMS;
  let framePerPixel = frameCount / len;

  if (framePerPixel < 1) {
    let tempSpeed = len / frameCount;
    if (len % frameCount > 0) tempSpeed++;
    setStepSize(tempSpeed);
    setDelayFrame(1);
  } else {
    setDelayFrame(framePerPixel);
    setStepSize(1);
  }
}
