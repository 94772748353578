import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import SolidShowPreview from './ShowHelpers/SolidShowPreview';
import JumpShowPreview from './ShowHelpers/JumpShowPreview';
import FadeShowPreview from './ShowHelpers/FadeShowPreview';
import SparkleShowPreview from './ShowHelpers/SparkleShowPreview';
import ScrollShowPreview from './ShowHelpers/ScrollShowPreview';
import ChaseShowPreview from './ShowHelpers/ChaseShowPreview';
import AlternateChaseShowPreview from './ShowHelpers/AlternateChaseShowPreview';
import SmoothWave from './ShowHelpers/SmoothWaveShowPreview';
import CurtainShowPreview from './ShowHelpers/CurtainShowPreview';
import SpaceMountainShowPreview from './ShowHelpers/SpaceMountainShowPreview';
import StackShowPreview from './ShowHelpers/StackShowPreview';
import SplitAlternateShowPreview from './ShowHelpers/SplitAlternateShowPreview';
import KnightRiderShowPreview from './ShowHelpers/KnightRiderShowPreview';
import { UserContext } from '../../context/UserStateManager';
import { useContext, useMemo } from 'react';
import { ExpandMore } from '@mui/icons-material';

interface ShowPreviewProps {
  selectedShow: string;
  colorCopy: number[][];
  speed: number;
  backward: boolean;
  zone?: number;
}

export default function ShowPreview({
  selectedShow,
  colorCopy,
  speed,
  backward,
  zone,
}: ShowPreviewProps) {
  const userCon = useContext(UserContext);

  const userZone =
    userCon.state.currentDevice?.configuration.settings.zones[zone || 0];

  const pixelLayout = userZone?.pixels || [
    [1, 2, 3, 4],
    [1, 2, 3, 4],
  ];

  const colors = useMemo(() => {
    return colorCopy.map((color) =>
      typeof color === 'string'
        ? userCon.state.currentOrg?.org.customColors.find(
            (ele) => ele._id == color,
          )?.value || [0, 0, 0]
        : color,
    );
  }, [colorCopy]);

  function renderShow() {
    if (selectedShow === 'SOLID') {
      return (
        <SolidShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'JUMP') {
      return (
        <JumpShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'FADE') {
      return (
        <FadeShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'SPARKLE') {
      return (
        <SparkleShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'FIRE') {
      return (
        <SparkleShowPreview
          colors={[
            [255, 0, 0],
            [255, 31, 0],
            [255, 143, 0],
          ]}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'SCROLL') {
      return (
        <ScrollShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
          backward={backward}
        />
      );
    } else if (selectedShow === 'CHASE') {
      return (
        <ChaseShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
          backward={backward}
        />
      );
    } else if (selectedShow === 'ALTERNATING_CHASE') {
      return (
        <AlternateChaseShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'SMOOTH_WAVE') {
      return (
        <SmoothWave
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
          backward={backward}
        />
      );
    } else if (selectedShow === 'RAINBOW') {
      return (
        <SmoothWave
          colors={[
            [255, 0, 0],
            [255, 31, 0],
            [255, 166, 0],
            [0, 255, 0],
            [0, 191, 255],
            [0, 0, 255],
            [166, 0, 255],
            [255, 0, 64],
          ]}
          pixelLayout={pixelLayout}
          speed={speed}
          backward={backward}
        />
      );
    } else if (selectedShow === 'CURTAIN') {
      return (
        <CurtainShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'SPACE_MOUNTAIN') {
      return (
        <SpaceMountainShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
          backward={backward}
        />
      );
    } else if (selectedShow === 'STACK') {
      return (
        <StackShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
          backward={backward}
        />
      );
    } else if (selectedShow === 'SPLIT_ALTERNATE') {
      return (
        <SplitAlternateShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    } else if (selectedShow === 'KNIGHT_RIDER') {
      return (
        <KnightRiderShowPreview
          colors={colors}
          pixelLayout={pixelLayout}
          speed={speed}
        />
      );
    }
  }
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Show Layout Preview:
        </AccordionSummary>
        <AccordionDetails>
          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Zone: {userZone?.name || 'None'}
          </span>
          <div
            style={{
              marginTop: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflowX: 'auto',
              overflowY: 'hidden',
              padding: '20px',
            }}
          >
            {renderShow()}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
