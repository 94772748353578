interface LightFixtureProps {
  pixel: number;
  row: number;
  column: number;
  color: number[];
}

export default function LightFixture({
  pixel,
  row,
  column,
  color,
}: LightFixtureProps) {
  if (pixel !== 0) {
    return (
      <div
        key={`${row}-${column}`}
        style={{
          width: '24px',
          height: '24px',
          backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
          border: '1px solid #CCC', // Optional border for better visualization
        }}
      ></div>
    );
  } else {
    return (
      <div
        key={`${row}-${column}`}
        style={{
          width: '24px',
          height: '24px',
          backgroundColor: `white`,
        }}
      ></div>
    );
  }
}
