import { useEffect, useState } from 'react';
import { BasicShowPreviewProps, parseLinearSpeed } from './helpers';
import LightFixture from '../LightFixture';

export default function SpaceMountainShowPreview({
  colors,
  pixelLayout,
  speed,
  backward = false,
}: BasicShowPreviewProps) {
  const [blockMid, setBlockMid] = useState(0); // Current position of the block
  const [direction, setDirection] = useState(backward ? -1 : 1); // Direction of movement
  const [delayFrame, setDelayFrame] = useState(1); // Frame delay
  const [stepSize, setStepSize] = useState(1); // Steps per movement
  const [blockWidth, setBlockWidth] = useState(1); // Width of the pulse
  const [blastColorIndex, setBlastColorIndex] = useState(0); // Current color index for the pulse

  function setDir(forward: boolean) {
    if (forward) {
      setDirection(1);
      setBlockMid(0);
    } else {
      setDirection(-1);
      setBlockMid(pixelLayout[0].length - 1);
    }

    if (blastColorIndex < colors.length - 1) {
      setBlastColorIndex(blastColorIndex + 1);
    } else setBlastColorIndex(0);
  }
  // Set the initial configuration when the component mounts or speed changes
  useEffect(() => {
    parseLinearSpeed(speed, pixelLayout[0].length, setStepSize, setDelayFrame);

    // Calculate the width of the block pulse
    const calculatedBlockWidth = Math.max(
      1,
      Math.floor(pixelLayout[0].length / 6),
    );
    setBlockWidth(calculatedBlockWidth);

    // Reset direction and starting position
    setDirection(backward ? -1 : 1);
    setBlockMid(backward ? pixelLayout[0].length - 1 : 0);
  }, [speed, pixelLayout, backward]);

  // Update block position and color at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      const nextMid = blockMid + direction * stepSize;
      setBlockMid(nextMid);

      // Check boundaries
      if (
        nextMid >= pixelLayout[0].length + blockWidth / 2 - 1 ||
        nextMid < -blockWidth / 2
      ) {
        // Reverse direction and switch color
        setDir(!backward);
      }
    }, delayFrame * 42);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [
    direction,
    stepSize,
    colors.length,
    blockWidth,
    pixelLayout,
    blockMid,
    backward,
  ]);

  // Function to determine pixel color based on block position
  const getPixelColor = (columnIndex: number): number[] => {
    const blockEdge1 = blockMid + blockWidth / 2;
    const blockEdge2 = blockMid - blockWidth / 2;

    if (columnIndex >= blockEdge2 && columnIndex <= blockEdge1) {
      return colors[blastColorIndex]; // Color of the pulse
    }

    return [0, 0, 0]; // Base color (off)
  };

  if (!colors || !colors.length || !pixelLayout) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          const color = getPixelColor(columnIndex);
          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
