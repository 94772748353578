import { useEffect, useState } from 'react';
import { BasicShowPreviewProps } from './helpers';
import LightFixture from '../LightFixture';

export default function ScrollShowPreview({
  colors,
  pixelLayout,
  speed,
  backward,
}: BasicShowPreviewProps) {
  const [currentIndex, setCurrentIndex] = useState(0); // Current scroll position.
  const [generatedArray, setGeneratedArray] = useState<number[]>([]); // Precomputed color blocks for the scroll.
  const parsedSpeed = parseSpeed(speed) * 4; // Convert speed into a delay value.

  function parseSpeed(speed: number) {
    switch (speed) {
      case 1:
        return 1000;
      case 2:
        return 900;
      case 3:
        return 800;
      case 4:
        return 700;
      case 5:
        return 600;
      case 6:
        return 500;
      case 7:
        return 400;
      case 8:
        return 300;
      case 9:
        return 200;
      case 10:
        return 100;
      default:
        return 500;
    }
  }

  // Generate a repeated array of color blocks based on block width.
  function generateColorArray() {
    const blockWidth = Math.max(
      Math.floor(pixelLayout[0].length / (1.5 * colors.length)),
      2,
    );
    const generated = [];
    for (let colorIndex = 0; colorIndex < colors.length; colorIndex++) {
      for (let i = 0; i < blockWidth; i++) {
        generated.push(colorIndex);
      }
    }
    return generated;
  }

  useEffect(() => {
    // Generate the scroll array when colors or pixel layout change.
    const newGeneratedArray = generateColorArray();
    setGeneratedArray(newGeneratedArray);
  }, [colors, pixelLayout]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex + (backward ? -1 : 1);
        // Loop the index within the length of the generated array.
        if (newIndex >= generatedArray.length) {
          return 0;
        } else if (newIndex < 0) {
          return generatedArray.length - 1;
        }
        return newIndex;
      });
    }, parsedSpeed);

    return () => clearInterval(interval); // Cleanup interval on component unmount.
  }, [parsedSpeed, backward, generatedArray]);

  if (!colors || !colors.length || !generatedArray.length) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity.
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          // Calculate the color index based on the scroll position.
          const colorIndex =
            generatedArray[
              (currentIndex + columnIndex) % generatedArray.length
            ];
          const color = colors[colorIndex];
          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
