import { useEffect, useState } from 'react';
import { BasicShowPreviewProps, parseLinearSpeed } from './helpers';
import LightFixture from '../LightFixture';

export default function StackShowPreview({
  colors,
  pixelLayout,
  speed,
  backward,
}: BasicShowPreviewProps) {
  const [blockEdge, setBlockEdge] = useState(0); // Current block edge position
  const [stackEnd, setStackEnd] = useState(pixelLayout[0].length - 1); // Stack end position
  const [direction, setDirection] = useState(1); // Direction of movement (1 = forward, -1 = backward)
  const [start, setStart] = useState(0);
  const [colorIndex, setColorIndex] = useState(0); // Current color index
  const [prevColorIndex, setPrevColorIndex] = useState(colors.length - 1); // Previous color index
  const [stepSize, setStepSize] = useState(1); // Step size for block movement
  const [delayFrame, setDelayFrame] = useState(1); // Delay frame for movement
  const [blockWidth, setBlockWidth] = useState(1); // Width of each stack block
  const [colorLeft, setColorLeft] = useState([0, 0, 0]);
  const [colorRight, setColorRight] = useState([0, 0, 0]);
  const [stackBlock, setStackBlock] = useState([0, 0, 0]);
  // Parse the speed into delay and step size

  // Calculate the block width
  function calculateBlockWidth() {
    const width = Math.max(1, Math.floor(pixelLayout[0].length / 7));
    setBlockWidth(width);
  }

  // Begin a new stack
  function beginNewStack(forward: boolean) {
    setPrevColorIndex(colorIndex);
    setColorIndex(colorIndex + 1 >= colors.length ? 0 : colorIndex + 1);

    if (forward) {
      setStart(0);
      setDirection(1);
      setBlockEdge(0);
      setStackEnd(pixelLayout[0].length - 1);
      setColorLeft(colors[colorIndex]);
      setColorRight(colors[prevColorIndex]);
      setStackBlock(colors[prevColorIndex]);
    } else {
      setStart(pixelLayout[0].length - 1);
      setDirection(-1);
      setBlockEdge(pixelLayout[0].length - 1 + blockWidth);
      setStackEnd(0);
      setColorLeft(colors[prevColorIndex]);
      setColorRight(colors[colorIndex]);
      setStackBlock(colors[prevColorIndex]);
    }
  }

  // Parse speed and initialize block width when component mounts or speed changes
  useEffect(() => {
    beginNewStack(!backward);
    parseLinearSpeed(speed, pixelLayout[0].length, setStepSize, setDelayFrame);
    calculateBlockWidth();
  }, [speed, pixelLayout]);

  // Effect to handle block movement and stack logic
  useEffect(() => {
    const interval = setInterval(() => {
      const nextBlockEdge = blockEdge + stepSize * direction;
      setBlockEdge(nextBlockEdge);

      if (!backward) {
        if (nextBlockEdge >= stackEnd) {
          const newStackEnd = stackEnd - blockWidth;
          setStackEnd(newStackEnd);
          setBlockEdge(start);
          if (newStackEnd <= start) {
            beginNewStack(true);
          }
        }
      } else {
        if (nextBlockEdge - blockWidth <= stackEnd) {
          setBlockEdge(start);
          const newStackEnd = stackEnd + blockWidth;
          setStackEnd(newStackEnd);
          if (newStackEnd >= start) {
            beginNewStack(false);
          }
        }
      }
    }, delayFrame * 42);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [
    blockEdge,
    backward,
    stackEnd,
    direction,
    stepSize,
    delayFrame,
    blockWidth,
    pixelLayout,
    start,
  ]);

  if (!colors || !colors.length || !pixelLayout) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          // Determine color based on block and stack positions;

          let color;
          let otherBlockEdge = blockEdge - blockWidth;
          if (columnIndex > otherBlockEdge && columnIndex <= blockEdge) {
            color = stackBlock;
          } else if (columnIndex <= stackEnd) {
            color = colorLeft;
          } else {
            color = colorRight;
          }

          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
