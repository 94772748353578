import { useEffect, useState } from 'react';
import { BasicShowPreviewProps } from './helpers';
import LightFixture from '../LightFixture';

export default function JumpShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  let [colorIndex, setColorIndex] = useState(0);
  let parsedSpeed = (parseSpeed(speed) / 24) * 1000;

  function parseSpeed(speed: number) {
    switch (speed) {
      case 1:
        return 52; //26
      case 2:
        return 24;
      case 3:
        return 22;
      case 4:
        return 20;
      case 5:
        return 18;
      case 6:
        return 16;
      case 7:
        return 14;
      case 8:
        return 12;
      case 9:
        return 10;
      case 10:
        return 8;
    }

    return 0;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, parsedSpeed);

    return () => clearInterval(interval); // Cleanup interval on component unmount.
  }, [colors.length, speed, parsedSpeed]);

  if (!colors || !colors.length) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity.
      }}
    >
      {pixelLayout.flat().map((color, idx) => {
        return (
          <LightFixture
            key={idx + idx}
            row={idx}
            column={idx}
            color={colors[colorIndex]}
            pixel={color}
          />
        );
      })}
    </div>
  );
}
