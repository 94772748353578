import { useEffect, useState } from 'react';
import { BasicShowPreviewProps } from './helpers';
import LightFixture from '../LightFixture';

export default function FadeShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  const [currentRGB, setCurrentRGB] = useState([0, 0, 0]); // Tracks the current color state.
  const [direction, setDirection] = useState(1); // 1 for fading in, -1 for fading out.
  const [colorIndex, setColorIndex] = useState(0); // Tracks the current color index.
  const parsedSpeed = parseSpeed(speed);

  function parseSpeed(speed: number) {
    switch (speed) {
      case 1:
        return 2;
      case 2:
        return 4;
      case 3:
        return 5;
      case 4:
        return 6;
      case 5:
        return 7;
      case 6:
        return 8;
      case 7:
        return 9;
      case 8:
        return 12;
      case 9:
        return 15;
      case 10:
        return 20;
    }

    return 0;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const targetColor = colors[colorIndex];

      const animate = () => {
        setCurrentRGB((prevRGB) => {
          const nextRGB = prevRGB.map((value, i) => {
            const diff = direction * parsedSpeed;
            const newValue = value + diff;

            // Clamp the value between 0 and the target RGB
            if (direction === 1) return Math.min(newValue, targetColor[i]);
            else return Math.max(newValue, 0);
          });

          // Check if we need to reverse direction or move to the next color
          if (
            direction === 1 &&
            nextRGB.every((value, i) => value >= targetColor[i])
          ) {
            setDirection(-1); // Start fading out
          } else if (direction === -1 && nextRGB.every((value) => value <= 0)) {
            setDirection(1); // Start fading in for the next color
            if (colorIndex < colors.length - 1) {
              setColorIndex(colorIndex + 1);
            } else setColorIndex(0);
          }

          return nextRGB;
        });
      };

      const animationFrame = requestAnimationFrame(animate);

      return () => cancelAnimationFrame(animationFrame); // Cleanup on unmount
    }, 42);
    return () => clearInterval(interval); // Cleanup interval on component unmount.
  }, [colorIndex, direction, colors, parsedSpeed]);

  if (!colors || !colors.length) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity.
      }}
    >
      {pixelLayout.flat().map((pixel, idx) => (
        <LightFixture
          key={idx + idx}
          row={idx}
          column={idx}
          color={currentRGB}
          pixel={pixel}
        />
      ))}
    </div>
  );
}
