import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex, rgbaToHsva } from '@uiw/color-convert';
import EditableInputRGBA from '@uiw/react-color-editable-input-rgba';
import { UserContext } from '../context/UserStateManager';
import { toast, ToastContainer } from 'react-toastify';
import { OrgColorContext } from '../context/OrgContext';
import { AddCircle, CloseOutlined, MoreVert } from '@mui/icons-material';
import { deleteOrgColor, saveOrgColor, textColorFromBg } from './helpers';
import { CustomColor } from './orgTypes';
import { COLORS } from '../Enums';
import ConfirmationModal from '../common/ConfirmationModal';

export function OrgColorPicker() {
  const matches = useMediaQuery('(min-width: 900px)');

  return (
    <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
      <Paper>
        <Typography variant="h3" style={{ marginBottom: 10 }}>
          Default Colors
        </Typography>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: matches ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)',
            gap: 10,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {COLORS.map((color) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: `${color.display}`,
                  width: 30,
                  height: 30,
                  borderRadius: '100px',
                  border: '1px solid',
                  borderColor: '#ebedeb',
                }}
              ></div>
              <Typography variant="body1" style={{ paddingLeft: 10 }}>
                {color.name}
              </Typography>
            </div>
          ))}
        </div>
      </Paper>
      <Paper>
        <CustomColorGrid />
      </Paper>
    </div>
  );
}

function CustomColorGrid() {
  const defaultColor: CustomColor = {
    name: '',
    _id: '',
    value: [255, 255, 255],
    display: '',
  };
  const defaultHsva = { h: 0, s: 0, v: 100, a: 1 };

  const [wheelOpen, setWheelOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  const [hsva, setHsva] = useState(defaultHsva);
  const [selectedColor, setSelectedColor] = useState<CustomColor>({
    name: '',
    _id: '',
    value: [],
    display: '',
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const inputStyle: React.CSSProperties = {
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: '16px',
  };
  const labelStyle: React.CSSProperties = { paddingTop: 6, fontSize: '16px' };
  const hiddenInputStyle: React.CSSProperties = { display: 'none' };
  const matches = useMediaQuery('(min-width: 900px)');

  const userContext = useContext(UserContext);
  const { customColorList, refetch } = useContext(OrgColorContext);

  const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
    if (
      wheelOpen &&
      colorPickerRef.current &&
      !colorPickerRef.current.contains(e.target as Node)
    ) {
      handleCloseWheel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    color: CustomColor,
  ) => {
    setAnchorEl(event.currentTarget);
    handleColorChange(color);
  };

  const handleColorChange = (color: CustomColor) => {
    setSelectedColor(color);
    setHsva(customColorValueToHsva(color.value));
  };

  const handleCloseWheel = () => {
    setWheelOpen(false);
    handleColorChange(defaultColor);
  };

  const customColorValueToHsva = (value: number[]) => {
    return rgbaToHsva({ r: value[0], g: value[1], b: value[2], a: 1 });
  };

  const onSubmit = async () => {
    if (!selectedColor.name) {
      toast.error('You must name your color!');
      return;
    }
    const response = await saveOrgColor(userContext, selectedColor, hsva);
    if (response.ok) {
      toast.success('Color saved!');
      handleCloseWheel();
      refetch();
    } else {
      toast.error('Error saving color!');
    }
  };

  const handleDeleteColor = async () => {
    const response = await deleteOrgColor(userContext, selectedColor);
    if (response.ok) {
      toast.success('Color deleted!');
      handleCloseWheel();
      setDeleteModalOpen(false);
      refetch();
    } else {
      toast.error('Error deleting color!');
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '80%' }}>
          <Typography variant="h3">Organization Colors</Typography>
          <Typography
            variant="body1"
            style={{ marginBottom: 10, fontSize: '12px' }}
          >
            Note: Colors selected may vary slightly from the actual colors
            displayed on your LED lights due to differences in screen and
            lighting technologies.
          </Typography>
        </div>

        <div
          style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => setWheelOpen(true)}
            style={{ alignItems: 'center', textTransform: 'none' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: matches ? 'row' : 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '2px',
              }}
            >
              <AddCircle style={{ fontSize: '2.5rem' }} color="primary" />
              <span style={{ display: matches ? 'block' : 'none' }}>
                Add Color
              </span>
            </div>
          </Button>
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: matches ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)',
          gap: 10,
        }}
      >
        {customColorList.map((color) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              key={color._id}
            >
              <div
                style={{
                  backgroundColor: `rgb(${color.value[0]},  ${color.value[1]}, ${color.value[2]})`,
                  width: 30,
                  height: 30,
                  borderRadius: '100px',
                  border: '1px solid',
                  borderColor: '#ebedeb',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '70%',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" style={{ paddingLeft: 10 }}>
                  {color.name}
                </Typography>
                <div>
                  <IconButton
                    style={{
                      padding: '0',
                      margin: '0 4px 0 auto',
                      fontSize: '36px',
                    }}
                    onClick={(e) => handleClickMenu(e, color)}
                  >
                    <MoreVert />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        })}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setWheelOpen(true);
              setAnchorEl(null);
            }}
          >
            Edit Color
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDeleteModalOpen(true);
              setAnchorEl(null);
            }}
          >
            Delete Color
          </MenuItem>
        </Menu>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Popover
            anchorOrigin={{
              vertical: 'center',
              horizontal: matches ? 'right' : 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: matches ? 'right' : 'center',
            }}
            open={wheelOpen}
            PaperProps={{
              style: {
                background: '#d4d4d4',
                padding: 10,
                borderRadius: '16px',
                width: 250,
              },
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              ref={colorPickerRef}
            >
              <IconButton
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 10,
                  padding: '7px',
                }}
                onClick={() => setWheelOpen(false)}
              >
                <CloseOutlined />
              </IconButton>
              <Wheel
                color={hsva}
                onChange={(color) => setHsva({ ...hsva, ...color.hsva })}
                width={250}
                height={250}
              />
              <div style={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    padding: '15px',
                    userSelect: 'none',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <EditableInputRGBA
                      hsva={hsva}
                      rProps={{ labelStyle, inputStyle }}
                      gProps={{ labelStyle, inputStyle }}
                      bProps={{ labelStyle, inputStyle }}
                      aProps={{
                        labelStyle: hiddenInputStyle,
                        inputStyle: hiddenInputStyle,
                      }}
                      onChange={(reColor) => setHsva(reColor.hsva)}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  background: hsvaToHex(hsva),
                  marginBottom: 15,
                  borderRadius: 4,
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    setSelectedColor({ ...selectedColor, name: e.target.value })
                  }
                  value={selectedColor.name}
                  placeholder="Color Name"
                  style={{ width: '100%' }}
                  InputProps={{
                    style: {
                      color: textColorFromBg(hsvaToHex(hsva)),
                    },
                  }}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => onSubmit()}
              >
                Save
              </Button>
            </div>
          </Popover>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={() => handleDeleteColor()}
          title="Delete Custom Color"
          confirmText="Delete"
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <span>Are you sure you want to delete {selectedColor.name}?</span>
            <div
              style={{
                backgroundColor: selectedColor.display,
                width: 30,
                height: 30,
                borderRadius: '100px',
                border: '1px solid',
                borderColor: '#ebedeb',
              }}
            ></div>
          </div>
          <span>Any shows using it will use white in its place.</span>
        </ConfirmationModal>
      </div>
    </>
  );
}
