import React, { useEffect, useState } from 'react';
import LightFixture from '../LightFixture';

interface SmoothWaveProps {
  colors: number[][];
  pixelLayout: number[][];
  speed: number;
  backward: boolean;
}

export default function SmoothWave({
  colors,
  pixelLayout,
  speed,
  backward,
}: SmoothWaveProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [parsedPixelArray, setParsedPixelArray] = useState<number[][]>([]);
  const [generatedArray, setGeneratedArray] = useState<number[][]>([]);
  const [direction, setDirection] = useState(1);
  const stepSize = 1;

  // Parse speed into delay and other parameters.
  const parsedSpeed = parseSpeed(speed) / 4;

  function parseSpeed(speed: number) {
    switch (speed) {
      case 1:
        return 1000;
      case 2:
        return 900;
      case 3:
        return 800;
      case 4:
        return 700;
      case 5:
        return 600;
      case 6:
        return 500;
      case 7:
        return 400;
      case 8:
        return 300;
      case 9:
        return 200;
      case 10:
        return 100;
      default:
        return 500;
    }
  }

  // Generate color transitions based on the input color array.
  function generateColorArray() {
    const transitionNumber = colors.length;
    const transitionSize = Math.max(2, Math.floor(36 / transitionNumber));
    const adjustedLength = transitionSize * transitionNumber;
    const tempGeneratedArray: number[][] = Array.from(
      { length: adjustedLength },
      () => [0, 0, 0, 0], // Initialize as black blobs
    );

    for (let t = 0; t < transitionNumber; t++) {
      const prevIndex = t;
      const nextIndex = (t + 1) % transitionNumber;

      const redStep =
        (colors[nextIndex][0] - colors[prevIndex][0]) / transitionSize;
      const greenStep =
        (colors[nextIndex][1] - colors[prevIndex][1]) / transitionSize;
      const blueStep =
        (colors[nextIndex][2] - colors[prevIndex][2]) / transitionSize;

      let red = colors[prevIndex][0];
      let green = colors[prevIndex][1];
      let blue = colors[prevIndex][2];

      for (let s = 0; s < transitionSize; s++) {
        const index = t * transitionSize + s;
        tempGeneratedArray[index] = [
          Math.min(255, Math.max(0, Math.round(red))),
          Math.min(255, Math.max(0, Math.round(green))),
          Math.min(255, Math.max(0, Math.round(blue))),
          0,
        ];
        red += redStep;
        green += greenStep;
        blue += blueStep;
      }
    }

    setGeneratedArray(tempGeneratedArray);
  }

  // Initialize the parsed pixel array and color array on mount or when pixelLayout changes.
  useEffect(() => {
    const animate = () => {
      const tempParsedPixelArray = pixelLayout.map((row) =>
        row.map((pixel) => (pixel !== 0 ? (pixel - 1) * 4 + 1 : 0)),
      );
      setParsedPixelArray(tempParsedPixelArray);
      parseSpeed(speed);
      generateColorArray();
    };
    const animationFrame = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrame); // Cleanup on unmount
  }, [pixelLayout, speed, colors]);

  // Handle animation updates.
  useEffect(() => {
    const interval = setInterval(() => {
      const animate = () => {
        setCurrentIndex((prevIndex) => {
          let newIndex = prevIndex + stepSize * direction;
          if (newIndex >= generatedArray.length - 1) newIndex = 0;
          if (newIndex < 0) newIndex = generatedArray.length - 1;
          return newIndex;
        });
      };
      const animationFrame = requestAnimationFrame(animate);

      return () => cancelAnimationFrame(animationFrame); // Cleanup on unmount
    }, parsedSpeed);

    return () => clearInterval(interval);
  }, [generatedArray, direction, stepSize, parsedSpeed]);

  // Update direction if backward flag changes.
  useEffect(() => {
    setDirection(backward ? -1 : 1);
  }, [backward]);

  if (
    !colors ||
    !colors.length ||
    !pixelLayout ||
    !generatedArray ||
    !generatedArray.length
  ) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px',
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          const colorIndex =
            (currentIndex + columnIndex) % generatedArray.length;
          const color = generatedArray[colorIndex];
          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
