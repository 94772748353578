import { useEffect, useState } from 'react';
import { BasicShowPreviewProps, parseLinearSpeed } from './helpers';
import LightFixture from '../LightFixture';

export default function KnightRiderShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  const [blockStart, setBlockStart] = useState(0); // Current position of the block
  const [direction, setDirection] = useState(1); // Direction of movement (1 = right, -1 = left)
  const [delayFrame, setDelayFrame] = useState(1); // Delay between frame updates
  const [blockWidth, setBlockWidth] = useState(2); // Width of the bouncing block
  const [stepSize, setStepSize] = useState(1);

  // Initialize speed parsing and block settings when speed or layout changes
  useEffect(() => {
    parseLinearSpeed(speed, pixelLayout[0].length, setStepSize, setDelayFrame);

    const calculatedBlockWidth = Math.max(
      Math.floor(pixelLayout[0].length / 4),
      1,
    );
    setBlockWidth(calculatedBlockWidth);
  }, [speed, pixelLayout]);

  // Handle frame updates for the Knight Rider effect
  useEffect(() => {
    const interval = setInterval(() => {
      const nextPosition = blockStart + direction * stepSize;
      setBlockStart(nextPosition);

      if (
        nextPosition >= pixelLayout[0].length + blockWidth - 2 ||
        nextPosition <= 0
      ) {
        setDirection(-1 * direction);
      }
    }, delayFrame * 42);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [delayFrame, direction, pixelLayout, blockWidth, blockStart, stepSize]);

  if (!colors || colors.length < 2 || !pixelLayout) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          // Determine if the current pixel is within the moving block
          const isBlock =
            columnIndex > blockStart - blockWidth && columnIndex <= blockStart;

          const color = isBlock
            ? colors[1] // Block color
            : colors[0]; // Base color

          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
