import { useEffect, useState } from 'react';
import { BasicShowPreviewProps } from './helpers';
import LightFixture from '../LightFixture';

export default function SparkleShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  let [colorArray, setColorArray] = useState([0]);

  let parsedSpeed = (parseSpeed(speed) / 24) * 1000;

  function parseSpeed(speed: number) {
    switch (speed) {
      case 1:
        return 52; //26
      case 2:
        return 24;
      case 3:
        return 22;
      case 4:
        return 20;
      case 5:
        return 18;
      case 6:
        return 16;
      case 7:
        return 14;
      case 8:
        return 12;
      case 9:
        return 10;
      case 10:
        return 8;
    }

    return 0;
  }

  function generateRandomArray() {
    var randomArray = [];
    if (colors.length === 3) {
      for (let i = 0; i < pixelLayout.flat().length; i++) {
        let random = Math.floor(Math.random() * 8);
        if (random < 4) {
          randomArray.push(0);
        } else if (random > 3 && random < 7) {
          randomArray.push(1);
        } else {
          randomArray.push(2);
        }
      }
    } else if (colors.length === 2) {
      for (let i = 0; i < pixelLayout.flat().length; i++) {
        let random = Math.floor(Math.random() * 8);
        if (random < 6) {
          randomArray.push(0);
        } else {
          randomArray.push(1);
        }
      }
    }
    return randomArray;
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setColorArray(generateRandomArray());
    }, parsedSpeed);

    return () => clearInterval(interval); // Cleanup interval on component unmount.
  }, [colors.length, speed, parsedSpeed]);

  if (!colors || !colors.length || colorArray.length === 1) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length}, 24px)`,
        gap: '2px', // Small gap between squares for clarity.
      }}
    >
      {pixelLayout.flat().map((pixel, idx) => {
        return (
          <LightFixture
            key={idx + idx}
            row={idx}
            column={idx}
            color={colors[colorArray[idx]]}
            pixel={pixel}
          />
        );
      })}
    </div>
  );
}
