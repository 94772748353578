import { useEffect, useState } from 'react';
import { BasicShowPreviewProps } from './helpers';
import LightFixture from '../LightFixture';

export default function SplitAlternateShowPreview({
  colors,
  pixelLayout,
  speed,
}: BasicShowPreviewProps) {
  const [delayFrame, setDelayFrame] = useState(1); // Delay between frame updates
  const [count, setCount] = useState(0); // Frame counter
  const [colorLeft, setColorLeft] = useState(0); // Index of the left color
  const [colorRight, setColorRight] = useState(1); // Index of the right color
  const [midPoint, setMidPoint] = useState(0); // Middle point of the grid

  // Parse speed into delay frames
  function parseSpeed(speed: number) {
    const delayFrames = [52, 24, 22, 20, 18, 16, 14, 12, 10, 8];
    setDelayFrame(delayFrames[speed - 1]);
  }

  // Initialize the midpoint and parse speed on mount or when speed changes
  useEffect(() => {
    parseSpeed(speed);
    setMidPoint(Math.floor(pixelLayout.length / 2) + (pixelLayout.length % 2));
  }, [speed, pixelLayout]);

  // Effect to handle alternating colors based on delay frame
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev + 1);

      if (count >= delayFrame) {
        setCount(0);
        setColorLeft((prev) => (prev + 1) % colors.length);
        setColorRight((prev) => (prev + 1) % colors.length);
      }
    }, 30);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [count, delayFrame, colors.length]);

  if (!colors || !colors.length || !pixelLayout) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${pixelLayout.length}, 24px)`,
        gridTemplateColumns: `repeat(${pixelLayout[0].length},24px)`,
        gap: '2px', // Small gap between squares for clarity
      }}
    >
      {pixelLayout.map((row, rowIndex) =>
        row.map((pixel, columnIndex) => {
          // Determine color based on row position (left or right side)
          const isLeft = rowIndex < midPoint;
          const color = isLeft ? colors[colorLeft] : colors[colorRight];

          return (
            <LightFixture
              key={rowIndex + columnIndex}
              row={rowIndex}
              column={columnIndex}
              color={color}
              pixel={pixel}
            />
          );
        }),
      )}
    </div>
  );
}
